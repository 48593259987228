import React from "react";
import styled, { ThemeContext } from "styled-components";
import loadable from "@loadable/component";
import { useStaticQuery, graphql } from "gatsby";
import WeedSVG from "../images/svg/weed.inline.svg";

const Garden = loadable(() => import("../components/garden"));

const WeedIcon = styled(WeedSVG)`
  position: fixed;
  height: 18px;
  top: 1rem;
  right: 0rem;
  z-index: 4;
  path {
    fill: ${(props) => props.theme.stoneBlack};
  }
`;

// markup
const IndexPage = () => {
  const themeContext = React.useContext(ThemeContext);

  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        proper
      }
    }
  `);

  const {
    sanitySiteSettings: { proper },
  } = data;

  return (
    <>
      {/* <a href={proper}>
        <WeedIcon />
      </a> */}

      <Garden hasLogo={true} theme={themeContext} />
    </>
  );
};

export default IndexPage;
